import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Benvinguts al curs intensiu sobre el <strong>Bastó Policial Extensible</strong>,
                    dissenyat per dotar els agents amb les competències necessàries per a la{" "}
                    <strong>tinença i ús efectiu</strong>
                    d'aquesta eina policial.
                </p>

                <p>
                    <strong>Objectius del curs:</strong>
                </p>

                <ul>
                    <li>Comprendre el marc legal que regula l'ús i la força.</li>
                    <li>Desenvolupar habilitats tècniques per a l'ús segur i efectiu.</li>
                    <li>Fomentar una actitud responsable i ètica a l'aplicació de la força.</li>
                </ul>

                <p>
                    <strong>Contingut del curs (5 hores):</strong>
                </p>

                <p>
                    <strong>Mòdul 1: Marc Legal</strong>
                </p>

                <ul>
                    <li>Reglament d'Armes (Reial decret 137/93).</li>
                    <li>Principis de l'ús de la força a Europa.</li>
                    <li>Llei Orgànica 2/1986 de Forces i Cossos de Seguretat.</li>
                    <li>Llei 16/1991 de Policies Locals.</li>
                </ul>

                <p>
                    <strong>Mòdul 2: Tècniques d'ús</strong>
                </p>

                <ul>
                    <li>Tipus de bastons i les seves característiques.</li>
                    <li>Defenses i utilitats del bastó.</li>
                    <li>Portabilitat i tècniques d'obertura i tancament.</li>
                    <li>Empunyadures i postures d'entrevista o guàrdia.</li>
                    <li>Zones d'impacte i formes de colpejament.</li>
                    <li>Tècniques d'immobilització amb el bastó plegat i desplegat.</li>
                    <li>Defensa contra armes blanques i contundents.</li>
                    <li>Conducció de persones i resolució de casos pràctics actius.</li>
                </ul>

                <p>
                    En acabar, els participants rebran un certificat acreditatiu que valida la seva
                    formació en l'ús responsable i eficaç del bastó policial extensible.
                </p>

                <p>Inscriu-te i eleva la teva preparació al següent nivell!</p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Forma't amb el nostre curs intensiu sobre el Bastó Policial Extensible i aconsegueix un certificat homologat! Aprèn l'ús legal i efectiu d'aquesta eina essencial, desenvolupa tècniques avançades i aplica-les amb responsabilitat. Inscriu-te ara i eleva la teva preparació al màxim!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
